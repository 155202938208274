<template >
  <Breadcrumb :urls="urls" v-if="comUnmount" :translationloaded="translationloaded" :translate="translate"></Breadcrumb>
    <WhatsAppButton></WhatsAppButton>
  <div id="product_detail_div"> 
    <ButtonLoader :loaderColor="`red`"
          :loaderStyle="pageloaderStyle"
          :cart_ajax="ajax"
          :loader_min_height="`36px`"
          >
  </ButtonLoader>
  <div class="ps-page--product single-product" v-if="productDetail!='' && translationloaded==true">
    <div class="ps-container">
      <div class="ps-page__container">
        <div class="ps-page__left">
          <div class="ps-product--detail ps-product--fullwidth">
            <div class="ps-product__header">
              <div class="ps-product__thumbnail pDetailLarge" data-vertical="true">
                
                <figure>
                 
                      <div class="ps-wrapper">
                                        <div class="ps-product__gallery" data-arrow="true">
                                            <a @click="getSliderImage(product_img_index,'sub')" v-if="productImages.length>1 && product_img_index>-1"  href="javascript:void(0)" class="slick-arrow" style="display: block;"><i class="fa fa-angle-left"></i></a>
                                            <div class="item">
                                                    <!-- <a :href="`${presentProductSliderImg}`"> -->
                                                     
                                                        <img :src="`${presentProductSliderImg}`" :alt="productDetail.alt??productDetail.prName" @click="enableImageZoom">
                                                    <!-- </a> -->
                                                    <!-- <image-zoom 
                                                      :regular="`${presentProductSliderImg}`">				
                                                    </image-zoom> -->
                                            </div>
                                           <a @click="getSliderImage(product_img_index,'add')" v-if="productImages.length>1 && product_img_index < productImages.length" href="javascript:void(0)" class="slick-arrow " style="display: block;" aria-disabled="true"><i class="fa fa-angle-right"></i></a>
                                        </div>
                     </div>

                     <!-- <zoomOnHover :img-normal="`${presentProductSliderImg}`" :img-zoom="`https://i.picsum.photos/id/996/600/600.jpg?hmac=C3DwKRx_CS34I9jb4PQZ_2NzTA15ag-2LIpr7idOFM4`" :scale="1.5" :disabled="true"
                      @loaded="onload" @resized="onresize"></zoomOnHover> -->
                  
                </figure>
                    <div class="ps-product__variants" data-item="5" data-md="5" data-sm="5" data-arrow="false">
                                    <div class="item"  v-for="(productImage,index) in productImages" :key="index">
                                        <img :src="`${web_admin_url}${productImage.imageUrl}`" :alt="productDetail.alt??productDetail.prName" @click="getSliderImage(index)">
                                    </div>
                                  
                    </div>
                    <!-- <div class=" mt-3">
                       <span class="flagTxtMainall " ><img src=" /assets/img/floag1.jpg" > uae</span>
                    </div> -->
                   
              </div>
              <div class="ps-product__info">
                 <h1>{{productDetail.prName}}</h1>
                    <div class="prdt-offer"  v-if="Number.isFinite(productDetail.specialPrice)&& productDetail.specialPrice!=0 && productDetail.unitPrice >productDetail.specialPrice">
                        {{offer}}% {{translate('off')}}
                 </div>
                <div class="ps-product__meta">
                   <p v-if="productBrand!='' && productBrand!='N/A' ">{{translate('brand')}}:<a href="javascript:vois(0)"> {{productBrand}}</a></p>
                   <!-- <p v-if="countryOfOrgin">{{translate('country')}}: {{countryOfOrgin}}</p> -->
                  <!--  <p v-if="productBrand!='' && productBrand!='N/A' ">More products by: 
                    <router-link :to="`/products/?brand=`">{{productBrand}}</router-link>
                    </p> -->
                
                </div>
                <h4 class="ps-product__price">
                  <!-- <div class="prdt-price"  v-if="Number.isInteger(productDetail.specialPrice)&& productDetail.specialPrice!=0 && productDetail.unitPrice >productDetail.specialPrice">
                    <span class="prduct-price-txt mr-lft0">Product MRP :</span>
                    <span class="ps_prdct-price red-text"><del>{{currency}} {{productPrice}}</del></span>
                    
                  </div> -->
                  <div class="prdt-price actual-prdt-price">
                    <!-- <span class="prduct-price-txt pl-0">OUR PRICE :</span> -->
                  
                    <template  v-if="productDetail.specialPrice && productDetail.specialPrice!=0 && productDetail.unitPrice >productDetail.specialPrice && productDetail.cartOptionId == null && productDetail.cartItemId == null">
                      <div class="originalPrice">
                        <span>Original Price: </span>
                        <span class="ps_prdct-price red-text"><del>{{currency}} {{productPrice}}</del></span>
                      </div>                       
                    </template>
                    <template  v-if="productDetail.specialPrice && productDetail.specialPrice!=0 && productDetail.unitPrice >productDetail.specialPrice && selected_weight.optionId == null ">
                      <div class="originalPrice">
                        <span>Original Price: </span>
                        <span class="ps_prdct-price red-text"><del>{{currency}} {{productPrice}}</del></span>
                      </div>
                       
                    </template>
                    <p class="ourPrice"><span class="ps_prdct-price">{{translate('ourprice')}}: {{currency}} {{ourPrice}}</span></p>
                   
                  </div>
                </h4>
               <div class="detail_timer" v-if="productDetail.dealTo">
                  <ProductTimer v-if="productDetail.dealTo" :product="productDetail"
                        :translationloaded="translationloaded"
                        :translate="translate"/>
               </div>
                 
                 
                <div class="prdt-save-txt" v-if="Number.isFinite(productDetail.specialPrice)&& productDetail.specialPrice!=0 && productDetail.unitPrice >productDetail.specialPrice">You Save:  {{currency}} {{discountAmount}}</div>
                <!-- <div v-if="productDetail.customOptionsJson" >
                  <select v-model="productweight"  @change="updateprice()">
                          <option   v-for="productweight in JSON.parse(productDetail.customOptionsJson)"  
                          :value="productweight" :key="productweight.productId" selected>{{productweight.optionLabel}}</option>
                                          
                  </select>
                </div> -->
                <!-- <p v-html="productDetail.shortDescription" class="mb-0"></p> -->
                <ul class="productDeatilBtnnav">
                  <!-- <li><a class="sizeBtn" href="#">12</a></li> -->
                  <template v-if="productDetail.stockAvailability !='Out of Stock'">
                    <li v-if="isInCart=='View in cart'">
                        <div >
                                <CartQtyCounter
                                  @updateCartQty="updateCart($event)" 
                                  :product_url_key="productDetail.urlKey"                           
                                  :qtyCont="qtcount"                                  
                                  :style="counterStyle"
                                
                              ></CartQtyCounter>
                              
                          </div>
                           <!-- <img style="display:inline-block" :src="`${web_admin_url}${productDetail.UomIcon}`" alt=""> -->
                    </li>
                        <li  v-if="isInCart!='View in cart'">
                           <input type="number" v-model="qtyInputCnt" class="qtyInput" />
                           <img :src="`${web_admin_url}${productDetail.UomIcon}`" alt="">
                        </li>
                        
                        <li>
                         
                        <a class="addToCartProdutDeatil" href="#" @click="addtocart(productDetail)"><span>
                            <img src="/assets/img/cart-icon.png"/></span>
                                <template v-if="cart_ajax=='closed'">
                                    {{translate(isInCart)}}
                                </template>
                                <template v-else>
                                        <ButtonLoader :loaderColor="loaderColor"
                                                :loaderStyle="loaderStyle"
                                                :cart_ajax="cart_ajax"
                                                :loader_min_height="`36px`"
                                                >
                                        </ButtonLoader>
                                </template>
                        </a>
                      </li>
                     
                      <li><a class="buynow-btn" href="#" @click="buyNow(productDetail)">{{translate('buyNow')}}</a></li>
                  </template>
                  <template v-else>
                    <li><div class="out_stock" data-v-0afcf9c2=""> {{translate('outOfStock')}}</div></li>
                  </template>
                  

                  <li>
                    <a class="addToWislistProdutDeatil" href="#" @click="addToWishList(productDetail)" >
                      <i class="fa fa-heart" aria-hidden="true"></i>{{translate('addtoWishlist')}}</a>
                  </li>
                </ul>

                <div class="avilabilty-text" v-if="variationData.length >0">
                                 
                        <select class="form-control variation_select" v-model="selectedVariation" @change="loadVariationProduct()">
                             <template v-for="(variation,index) in variationData" :key="index">
                                   <option :value="variation">{{variation.AttrValuename}}
                                    <template v-if=" Number.isFinite(variation.prSpecialPrice) && variation.prSpecialPrice!=0 && variation.prPrice >variation.prSpecialPrice ">
                                        <del> {{currency}} {{variation.prPrice}} </del> - {{currency}} {{variation.prSpecialPrice}}
                                    </template>
                                    <template v-else>
                                        {{currency}} {{variation.prPrice}}
                                    </template>
                                  
                                   </option>
                              </template>

                        </select>            
                                    
               </div>
               <!-- <div class="avilabilty-text" v-if="weightselectedVariation" >
                 <select class="form-control variation_select" v-model="selected_weight" @change="loadWeightProduct()">
                        
                       <option  v-for="weights in weightselectedVariation" :key="weights.optionId"  :value="weights">{{weights.optionLabel}}</option>
                   
                 </select>
               </div> -->

                <div class="avilabilty-text" v-if="weightselectedVariation" >
                      <!-- <select class="form-control v_select"  v-model="selected_variation"  @click="UpdateProductUrlKey"> -->
                      <select class="form-control variation_select" v-model="selected_weight"  @click="loadWeightProduct">
                          <template v-for="(weights,index) in weightselectedVariation" :key="index">
                              <option :value="weights">{{weights.optionLabel}}

                                    <!-- <template v-if=" Number.isFinite(variation.prSpecialPrice) && variation.prSpecialPrice!=0 && variation.prPrice >variation.prSpecialPrice ">
                                       <span class="forDesk"> <del> {{currency}} {{variation.prPrice}} </del> - {{currency}} {{variation.prSpecialPrice}} </span>
                                    </template>
                                    <template v-else>
                                      <i class="forDesk">  {{currency}} {{variation.prPrice}}</i>
                                    </template> -->

                              </option>
                          </template>

                      </select>

                    </div>

               <!-- <div class="ps-product__sharing">
                  <h5>{{translate('shareThisProduct')}}</h5>
                  <a href="javascript:void(0)" class="facebook"><i class="fa fa-facebook" @click="shareItem('facebook')"></i></a>
                  
                  <a href="javascript:void(0)" class="mail"><i class="fa fa-envelope"  @click="shareItem('envelope')"></i></a>
                  <a href="javascript:void(0)" class="instagram"><i class="fa fa-instagram"  @click="shareItem('instagram')"></i></a>
                  <a href="javascript:void(0)" class="whatsapp"><i class="fa fa-whatsapp"  @click="shareItem('whatsapp')"></i></a>
              </div> -->

                <h5 class="whyCentralFontTxt">{{translate('whyShopFromKhairatLebanon')}}</h5>
                <ul class="shop-now">
                  <li>
                    <p>
                      <span><img src="/assets/img/refund-icon.png"/></span>{{translate('easyReturnsAndRefund')}}<br />
                     {{translate('returnProductsAtdoorstepAndGetRefund')}}
                    </p>
                  </li>
                  <li>
                    <p>
                      <span><img src="/assets/img/aed.png"/></span>
                      {{translate('bestQualityProducts')}}
                      <br />
                      {{translate('getBestQualityProductAtLowestPrice')}}..
                    </p>
                  </li>
                  <li>
                    <p>
                      <span><img src="/assets/img/quick-delivery-icon.png"/></span>
                       {{translate('quickDelivery')}}
                      <br />
                       {{translate('getProductsDeliveredtoYourDoorstepInNoTime')}}
                     
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div class="prdt-block">
              <div class="overview-txt">
                <h5>{{translate('productDetail')}}</h5>
                  <h4 class="mt-3">{{ translate('aboutThisItem')}}:</h4>
                <div v-html="productDetail.description"></div>
                 <h4 class="mt-3">{{translate('countryOfOrigin')}} : {{productDetail.CountryOfOrigin}}</h4>
                <p>{{translate('disclaimer')}} : {{disclaimer}}</p>
              </div>
            </div>
          </div>
          <div class="featured-box" v-if="featured_products!=''">
            <aside class="widget widget_same-brand">
              <h4>{{translate('featuredItem')}}</h4>
              <div class="widget__content">
                  <template v-for="(product,index) in featured_products" :key="index">
                   <!-- <FeaturedProduct :product="product" 

                        :index="index"
                        @refreshpage ="refreshData" 
                        :web_admin_url="web_admin_url"                       
                        :cust_id="cust_id"
                        :guest_id ="guest_id"
                        :translationloaded="translationloaded"
                        :translate="translate"
                        >

                   </FeaturedProduct> -->
                      <template v-if="index <2">
                        <FeaturedProduct                                                            
                          :product="product"
                          :web_admin_url="web_admin_url" 
                          @addToCart="addtocart($event)"
                          :cart_ajax="cart_ajax"
                          :cart_product_url_key="cart_product_url_key"
                          :currency="currency"
                          :is_banner="true"
                          :offerBadge="true"
                          :newBadge="true"
                          @refreshpage ="refreshData" 
                          @updateCartQty="updateCart($event)" 
                          :translationloaded="translationloaded"
                          :translate="translate"
                          >
                          </FeaturedProduct>

                      </template>
                  </template>
                   <router-link :to="`/featured-product`" class="viewAllBtn">{{translate('viewAll')}}</router-link>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="recommend-customers" v-if="productDetail!=''">
  
    <h3>{{translate('customersWhoViewedThisItemAlsoViewed')}}</h3>
   
    <div class="ps-section__content">
      <ImageSlider
        :products="related_products"
        :web_admin_url="web_admin_url"
        @refreshPagedata="refreshData"
        :currency="currency"
      ></ImageSlider>
    </div>
  </div>



  <div class="ps-deal-of-day mb-4" v-if="getRecentProducts.length>4!=''  && productDetail!=''">
    <div class="ps-container">
      <div class="ps-section__header">
        <div class="ps-block--countdown-deal">
          <div class="ps-block__left">
            <h3>{{translate('inspiredByYourBrowsingHistory')}}</h3>
          </div>
        </div>
      </div>

      <div class="ps-section__content" >
        <div  class="ps-carousel--nav owl-slider"    >
          <ProductSlider
            :products="getRecentProducts"
            :offerBadge="true"
            :newBadge="true"
            @refreshSliderdata="refreshImageData"
             :translationloaded="translationloaded"
            :translate="translate"
          ></ProductSlider>
        </div>
      </div>
    </div>
  </div>

  <div class="browse-history" v-if="false">
    <div class="container">
      <h4>
        Your Browsing history <span>View or Edit Your Browsing history</span>
      </h4>
      <ul>
        <template v-for="(product, index) in related_products" :key="index">
          <li v-if="index < 11">
            <img :src="`${web_admin_url}${product.imageUrl}`" alt="" />
          </li>
        </template>
      </ul>
    </div>
  </div>


   <!-- <div class="error_page" v-if="!productDetail.length && ajax=='closed'">
        <h1>Requested item not found</h1>
    </div> -->
     <span class="scrollTop" @click="topToBottom">
                          <i class="fa fa-angle-up" aria-hidden="true"></i>
    </span>

    <vue-easy-lightbox
    :visible="visible"
    :imgs="getZoomImages"
    :index="index"
    @hide="handleHide"
  ></vue-easy-lightbox>
    </div>
</template>
<script>
import Config from "@/config";
import helper from "@/helper/helper";
import axios from "axios";
import ProductSlider from "@/components/ProductSlider";
import ImageSlider from "@/components/ImageSlider";
 import ButtonLoader from '@/components/ButtonLoader';
import Breadcrumb from "@/components/Breadcrumb";
import CartQtyCounter from "@/components/cart/CartQtyCounter";
import WhatsAppButton from "@/components/WhatsAppButton";
import FeaturedProduct from "@/components/FeaturedProduct"
// import ProductCard from '@/components/ProductCard';
import ProductTimer from '../components/product/ProductTimer';


import { mapActions, mapGetters } from "vuex";

import $ from "jquery";
export default {
  name: "ProductDetail",
  components: {
    ProductSlider,
    ImageSlider,
     ButtonLoader,
    Breadcrumb,
    CartQtyCounter,
    WhatsAppButton,
    // ProductCard
    FeaturedProduct,
    ProductTimer,   
    // imageZoom
  },
  props: ["changed_url",'translationloaded',
      'translate', 'updateCartQty'],
  watch: {
   
    changed_url(url_key) {
     
this.loadInitialData(url_key);

         
      
    },
      $route() {
        helper.backtoTop();
        
    }
  },

  data() {
    return {
      productDetail: [],
      productDetailraw: [],
      web_admin_url: "",
      currency: "",
      productSpecialDetail: [],
      productImages: [],
      variationData: [],
      CustomWeightData: null,
      store_name: "",
      presentProductSliderImg: "",
      product_img_index: 0,
      admin_api_header: "",
      related_products: [],
      home_kitchen_products: [],
      featured_products: [],
      cart_product_url_key: "",
      loaderColor: "#fff",
      cart_ajax:"closed",
      ajax:"closed",
      vendor_url_key: "",
      loaderStyle: {
        width: "40px",
      },
       pageloaderStyle: {
        width: "40px",
        marginTop:"20px"
      },
      guest_id: "",
      cust_id: null,
      comUnmount:false,
      qtyInputCnt:1,
      productweight:null,
      urls: [
        {
          path: "/",
          name: "Home",
        },
         {
          path: "/all-category",
          name: "All Categories ",
        },
        {
          path: "/products",
          name: "Product detail",
        },
      ],
      countryOfOrgin:'',
      counterStyle:{
        'padding': '6px 0px'
      },
       visible: false,
       index: 0,
       selectedVariation:'',
       weightselectedVariation:'',
       selected_weight:'',
       qtcount:1
      //  optionsWeight:JSON.parse(this.productDetail.customOptionsJson),
    };
  },
  
  mounted() {
   
    this.storeRecentViewedProducts();    
    let $this = this;
    let parameters = this.$route.params;
    let url_key = parameters.url_key;
    $this.admin_api_header = Config.ADMIN_API_HEADER;
    $this.guest_id = localStorage.getItem("guestId");
    $this.cust_id = localStorage.getItem("custId");
    helper.backtoTop();

    $this.loadInitialData(url_key);

    $this.web_admin_url = Config.WEB_ADMIN_URL;
    $this.currency = Config.CURRECNCY;
    $this.store_name = Config.STORE_NAME;
    $this.vendor_url_key = Config.VENDOR_URL_KEY;

    if(this.$route.query.loc!=''){
     
      this.setStoreLocation(this.$route.query.loc);
      
    }
 
  },
  methods: {
    ...mapActions(["storeCustomerWishlist","storeRecentViewedProducts"]),
    loadVariationProduct(){
        // console.log(this.selectedVariation);
        if(this.selectedVariation){
          // console.log(this.selectedVariation.prUrlkey);
           let url='/Product-detail/'+this.selectedVariation.prUrlkey;
            this.$router.push({'path':url});
            this.refreshData(this.selectedVariation.prUrlkey);
        }
    },
    loadWeightProduct(){
        if(this.selected_weight){ 
        
            
         if(typeof (this.selected_weight.optionId) !== "undefined" ){
            this.productDetail.unitPrice = this.selected_weight.price
                // this.productUrlKey = this.product.urlKey;
         }
         else {
            this.productDetail.unitPrice = this.selected_weight.price;
           
             
           
         }
        //  else if(!this.product.variationJson)
        //  {
        //      this.productUrlKey=this.product.urlKey;
        //      this.productname=this.product.prName;
        //      this.productSpecialPrice=this.product.specialPrice;
        //      this.productPrice = this.product.unitPrice;
        //      console.log(this.product.urlKey);
        //  }
      }
    },
    // updateprice(){

    //   if(typeof this.productweight.optionId == "undefined"){
    //       let parameters = this.$route.params;    
    //       let url_key = parameters.url_key;
    //       this.loadInitialData(url_key);
    //   }else {
    //      this.productDetail.specialPrice = this.productweight.price;
    //      this.productDetail.unitPrice = this.productweight.price; 
        
    //   } 
        
    // },
    topToBottom(){
       helper.backtoTop();  
    },
      showImg(index) {
        this.index = index
        this.visible = true
      },
      handleHide() {
        this.visible = false
      },
      enableImageZoom(){
           this.visible = true;
      },
      setStoreLocation(vendor_url_key){        
       if(vendor_url_key){
            let decrypt =atob(vendor_url_key);
            // let locationInfo =JSON.parse(decrypt);
            // this.$cookies.set('location_title',this.location);
            this.$cookies.set('location_info',decrypt);
            localStorage.setItem('location_info',decrypt);
       }
       
      },
    
    loadInitialData(url_key) {
      let $this = this;
      let guestId = null;
      let custId = null;
      custId = localStorage.getItem("custId");
      if (custId == null) {
        guestId = localStorage.getItem("guestId");
      }

      $this.ajax='open';

      axios.get(
          `${Config.BASE_URL}ProductDetails?urlKey=${url_key}&custId=${custId}&guestId=${guestId}`,
          $this.admin_api_header
        )
        .then(function(response) {
          $this.productDetail = response.data.Data.ProdDetails;
           $this.weightselectedVariation = JSON.parse($this.productDetail.customOptionsJson); 
            
                $this.qtcount = $this.productDetail.CartItemQty;
           
                // console.log($this.productDetail.CartItemQty);
             
             
              if($this.productDetail.cartOptionId==null){
                
                 if($this.weightselectedVariation){
              $this.selected_weight = $this.weightselectedVariation[0];
            }
              
              } else  if($this.productDetail.cartOptionId!=null){
                let productOption = $this.productDetail.customOptionsJson;  
          if(productOption){
        let tweight = null;
        let item =JSON.parse(productOption);
           $this.CustomWeightData = item;
          
         for (let i = 0; i < item.length; i++) {
            tweight = [item[i]];
            if(tweight[0].optionId == $this.productDetail.cartOptionId ){      
              // console.log(tweight[0]);
              $this.selected_weight =tweight[0];
              $this.productDetail.unitPrice = tweight[0].price

            }          
        } 
      }
     }

           
          if($this.productDetail!=null){
             $this.updateMetaDetails($this.productDetail);
              $this.specDetails = response.data.Data.specDetails;
              $this.productImages = response.data.Data.ProdImages;
              let variationJson = response.data.Data.ProdDetails.variationJson;
              variationJson = JSON.parse(variationJson);
              $this.presentProductSliderImg =
                Config.WEB_ADMIN_URL + response.data.Data.ProdImages[0].imageUrl;
                  $("meta[name='og_image']").attr('content',$this.presentProductSliderImg);
               $this.getBrudCumProductName();
                $this.ajax='closed';
              if (variationJson != "") {
                if (variationJson.length > 0) {
                  $this.variationData = variationJson[0].AttrValues;
                  if($this.variationData!=''){
                    $this.selectedVariation =$this.variationData[0];
                  }
                }
              }
              
            
            
             
          }else{
             
              $this.ajax='closed';
             // $this.$router.push({'path':'/item_not_found'});
          }
        
        }).catch(function(){
       
             $this.cart_ajax='closed';
        });

      axios
        .get(
          `${Config.BASE_URL}RelatedProducts?urlKey=${url_key}&custId=${guestId}&guestId=${guestId}`,
          $this.admin_api_header
        )
        .then(function(response) {
          $this.related_products = response.data.Data.RelatedList;
        });

      axios
        .get(
          `${Config.BASE_URL}RelatedProducts?urlKey=home-care--cleaning&custId=${guestId}&guestId=${guestId}`,
          $this.admin_api_header
        )
        .then(function(response) {
          $this.home_kitchen_products = response.data.Data.RelatedList;
        });

      axios
        .get(
          `${Config.BASE_URL}FeaturedProduct?custId=${custId}&guestId=${guestId}`,
          $this.admin_api_header
        )
        .then(function(response) {
          $this.featured_products = response.data.Data;
        });
    },
    getSliderImage(index, nav = "") {
      let imgIndex = this.productImages.length - 1;

      if (nav == "") {
        this.product_img_index = index;
      } else if (nav == "add") {
        if (index < imgIndex && index >= 0) {
          this.product_img_index = index + 1;
        } else {
          this.product_img_index = 0;
        }
      } else if (nav == "sub") {
        if (index > 0) {
          this.product_img_index = index - 1;
        } else {
          this.product_img_index = 0;
        }
      }

      let selectedImg = this.productImages[this.product_img_index];
      this.presentProductSliderImg =
        Config.WEB_ADMIN_URL + selectedImg.imageUrl;
    },
    refreshData(product_urlKey) {
     
      this.$emit("refreshpage", product_urlKey);
    },
    refreshImageData(product_urlKey) {
    
      this.$emit("refreshpage", product_urlKey);
    },

    discountPercentage(product) {
      if (product.unitPrice > product.specialPrice) {
        let difference = product.unitPrice - product.specialPrice;

        let percentage = (difference * 100) / product.unitPrice;

        return percentage.toFixed(2);
      } else {
        return 0;
      }
    },
    buyNow(productInfo) {
      let isLoggedIn = helper.isLoginedUser();
      if (isLoggedIn == true) {
        let $this = this;
        this.addtocart(productInfo).then(function() {
          $this.$router.push({ path: "/checkout" });
        });
      } else {
        this.$router.push({ path: "/", query: { login: "failed" } });
      }
    },
    addtocart(productInfo) {
      if (this.isInCart == "Add to cart"|| productInfo.isFeatured) {
        return new Promise((resolve, reject) => {
          let urlKey = productInfo.urlKey;
          let productQty = productInfo.product_qty??this.qtyInputCnt;
          let optionId_ = null;
          let $this = this;          
          $this.cart_ajax = "open";

          $this.cart_product_url_key = productInfo.urlKey;

            if(typeof(productInfo.optionId) !== "undefined"){
              console.log(productInfo.optionId);
              optionId_ = productInfo.optionId;
            }
            else{
                console.log(productInfo);
                // console.log($this.selected_weight);
               if($this.selected_weight.productId == productInfo.productId){
                    if(typeof($this.selected_weight.optionId)  !== "undefined"){
                    optionId_ =$this.selected_weight.optionId;
                } else {
                  optionId_ = null;
                }
               }
               else {
                  optionId_ = null;
               }
                
            }

        

        $this.qtcount=productQty;

          let payload = {
            cusId: $this.cust_id != null ? $this.cust_id : "",
            guestId: $this.cust_id == null ? $this.guest_id : "",
            pincode: $this.pincode,
            urlKey: urlKey,
            productQty: productQty,
            vendorUrlkey: $this.vendor_url_key,
            optionId: optionId_
          };
        //  console.log("payload");
        //  console.log(payload);

          $this.$store
            .dispatch("addToCart", { payload: payload, toast: $this.$toast })
            .then(function(response) {
              resolve(response);
              $this.cart_ajax = "closed";
              $this.cart_product_url_key = "";
              $this.customerCart = $this.$store.state.customer_cart;             
              $this.$emit("cartUpdated", $this.customerCart);
            })
            .catch(function(error) {
              reject(error);
            });
        });
      } else {
        $("#cart-mobile").css({ left: "unset", right: "0" });
        $(".ps-panel--sidebar").removeClass("active");
        $("#cart-mobile").addClass("active");
        $(".ps-site-overlay").addClass("active");
      }
    },
     updateCart(cartItemInfo){
      //  alert("123")
        console.log(cartItemInfo);
        this.qtcount = cartItemInfo.product_qty;
  
        let guestId=null;
        let custId =null;
        custId=localStorage.getItem("custId");
        if(custId==null){
            guestId =localStorage.getItem("guestId");
        }

      
       if(cartItemInfo.product_qty==0){
        //  console.log("1");
          this.rmvCartItem(cartItemInfo);
       }else{
             let  $this =this;  
                let cartUpdateUrl ='';
                if(cartItemInfo.type =='add' ){
                    cartUpdateUrl =`${Config.BASE_URL}Order/CartItemAddQtyByUrlKey`;
                }else if(cartItemInfo.type =='sub'){       
                    cartUpdateUrl =`${Config.BASE_URL}Order/CartItemSubQtyByUrlKey`;
                }
                if(cartUpdateUrl!=''){
                     axios.post(`${cartUpdateUrl}`,{
                       "urlKey":cartItemInfo.url_key,
                        "guestId": guestId,
                        "custId":custId
                     },Config.ADMIN_API_HEADER).then(function(response){
                        if(response.status==200){
                            
                             

                               let payload ={
                                'cusId':custId,
                                'guestId':guestId,
                                'vendorUrlkey': Config.VENDOR_URL_KEY         
                                };
                                $this.$store.dispatch("getCart",payload).then(function(){                                   
                                    $this.$emit('cartUpdated',$this.cartItemInfo);
                                   // $this.storeCartSummary();
                                  
                                });
                            
                        }
                    }).catch(function(error){                       
                        $this.$toast.error(`${error.response.data.Message}`);
                         $this.qtyReset =1;
                    });
                }

       }
      
             
    },
    rmvCartItem(cartItemInfo){
       let $this = this;
       $this.cart_ajax = 'open';
       let guestId=null;
        let custId =null;
        custId=localStorage.getItem("custId");
        if(custId==null){
            guestId =localStorage.getItem("guestId");
        }

    
         axios.post(`${Config.BASE_URL}Order/RemoveCartItemByUrlkey`,{
                       "urlKey":cartItemInfo.url_key,
                        "guestId": guestId,
                        "custId":custId
                     },Config.ADMIN_API_HEADER).then(function(response){
                        if(response.status==200){
                             $this.cart_ajax = 'closed';                             

                               let payload ={
                                'cusId':custId,
                                'guestId':guestId,
                                'vendorUrlkey': Config.VENDOR_URL_KEY         
                                };
                                $this.$store.dispatch("getCart",payload).then(function(){                                   
                                    $this.$emit('cartUpdated',$this.cartItemInfo);
                                   // $this.storeCartSummary();
                                  
                                });
                            
                        }
                    }).catch(function(error){                       
                        $this.$toast.error(`${error.response.data.Message}`);
                         $this.qtyReset =1;
                    });
    
  },
    addToWishList(productInfo) {
      let isLoggedIn = helper.isLoginedUser();
      if (isLoggedIn == true) {
        let $this = this;
        let payload = {
          custId: $this.cust_id != null ? $this.cust_id : "",
          guestId: $this.cust_id == null ? $this.guest_id : "",
          urlKey: productInfo.urlKey,
        };

        $this.storeCustomerWishlist(payload).then(function(response) {
          $this.$toast.success(`${response.data.Message}`);
        });
      } else {
        
             document.getElementById("login_popup_link").click(); 
          
            // this.$router.push({ path: "/", query: { login: "failed" } });
      }
    },
   
    updateMetaDetails(){
        //  item.metaTitle?document.title = item.metaTitle:'';
        //  item.shortDescription?$('meta[name=description]').attr('content', item.shortDescription):'';
        //  item.metaKeywords?$('meta[name=keywords]').attr('content', item.metaKeywords):'';
    },
    shareItem(socialmedia){
      let shereUrl='';
      let locationInfo = localStorage.getItem('location_info');
       let encrypted = btoa(locationInfo);
      let pageUrl =`${Config.SITE_URL}${this.$route.path}?loc=${encrypted}`;
      if(socialmedia=='facebook'){
           shereUrl = "https://www.facebook.com/sharer.php?u=" + pageUrl;
      }
      if(socialmedia=='envelope'){
           shereUrl = "mailto:?subject=" + this.productDetail.prName + "&amp;body=Check out this site" + pageUrl;
      }
      if(socialmedia=='instagram'){
           shereUrl = "https://www.instagram.com/?url=" + pageUrl;
      }
      if(socialmedia=='whatsapp'){
           shereUrl = "https://wa.me/?text=" + pageUrl;
      }
      window.open(shereUrl, "_blank");
      
    },
    getBrudCumProductName(){

      // console.log(JSON.stringify(this.urls));
        if(this.productDetail!=''){
            this.urls[1]={'path':`/products/${this.productDetail.catUrlKey}`,'name':this.productDetail.catName}; 
            this.urls[2].name=this.productDetail.prName;         
            this.comUnmount = false;
            this.$nextTick(() => {              
                  this.comUnmount = true;
              });
        }

    },
    
    
      
  },
  computed: {
    ...mapGetters(["getcartList","getUomData","fetchCompanyPolicyData","getRecentProducts"]),
    getZoomImages(){
      let images =[];
       let $this=this;
      if($this.productImages){
        
       
          $this.productImages.forEach(function(item){
          images.push(`${ $this.web_admin_url}${item.imageUrl}`);
        });
       
      }
      
       return images;
    },
    productBrand() {
      let brand = "";
     let $this=this;
      if ( $this.specDetails != "") {
         $this.specDetails.forEach(function(item) {
          if (item.attrName.toLowerCase() == "brand") {
            brand = item.attrValue;
          }
          if(item.attrName.toLowerCase() == "country of origin"){
             $this.countryOfOrgin= item.attrValue;
             
          }
        });
      }

      return brand;
    },
    productPrice() {
      let price = 0;
      if (this.productDetail != "") {
        price = this.productDetail.unitPrice;
      }

      return price.toFixed(2);
    },
    ourPrice() {
      let specialPrice = 0;
       let price = null;
      if (this.productDetail != "") {
        if(typeof (this.selected_weight.optionId) !== "undefined"){
           price =null
          
        }
        else{
         price   = this.productDetail.specialPrice;
        }
        
        if ( Number.isFinite(price) && price != 0 && price != null) {
          
          specialPrice = price;
        } else {
          specialPrice = this.productDetail.unitPrice;
        }
      }

      return specialPrice.toFixed(2);
    },
    offer() {
      let offer = 0;
      if (this.productDetail != "") {
        let specialPrice = this.productDetail.specialPrice;
        let unitPrice = this.productDetail.unitPrice;
        let difference = 0;
        if (Number.isFinite(specialPrice) && Number.isFinite(unitPrice)) {
          difference = unitPrice - specialPrice;
        }
        if (difference != 0) {
          offer = helper.calculatePercentage(unitPrice, difference);
        }
      }
      return offer.toFixed(2);
    },
    discountAmount() {
      let discount = 0;
      if (this.productDetail != "") {
        let specialPrice = this.productDetail.specialPrice;
        let unitPrice = this.productDetail.unitPrice;
        if (Number.isFinite(specialPrice) && Number.isFinite(unitPrice)) {
          discount = unitPrice - specialPrice;
        }
      }

      return discount.toFixed(2);
    },
    isInCart() {
      // alert("123")
      let $this = this;
      let addcartBtnText = "Add to cart";
      if($this.productDetail.stockAvailability !='Out of Stock'){
        // console.log($this.productDetail);
           this.getcartList.forEach(function(cart) {
            
             
           

          if($this.productDetail.urlKey  == cart.urlKey){   
          //  alert("1cmp")      
              if(typeof($this.selected_weight.optionId) != "undefined" ){
                // alert("2cmp")
                if($this.selected_weight.optionId == cart.optionId){
                    //  console.log($this.selected_weight.optionId);
                  addcartBtnText = "View in cart";
                  $this.qtcount = cart.qty;
                  //  console.log(cart.qty);

                }
                else if($this.productDetail.customOptionsJson == null){
                  // alert("3cmp")
                  addcartBtnText = "View in cart";
                  $this.qtcount = cart.qty;
                  //  console.log(cart.qty);
                }
                
              }
              else if($this.productDetail.CartItemQty != null &&  cart.optionId == null){
                  // alert("4cmp")
                    // console.log($this.productDetail.CartItemQty);
                     addcartBtnText = "View in cart";
                    $this.qtcount = cart.qty;
                    //  console.log(cart.qty);
              }
              else if($this.productDetail.customOptionsJson == null){
                //  alert("5cmp")
                 addcartBtnText = "View in cart";
                 $this.qtcount = cart.qty;
                //  console.log(cart.qty);
              }
              

            
            

          }


        //     if($this.selected_weight.optionId =='undefind' || $this.selected_weight.optionId == null && cart.optionId == null)
        //     {
        //       //  alert("1")
              
          
        //    if (cart.urlKey == $this.productDetail.urlKey  && cart.optionId == $this.selected_weight.optionId) {
        //     //  alert("2")
        //   addcartBtnText = "View in cart";
         
        // }
        //  }
        //  else if (cart.urlKey == $this.productDetail.urlKey  ) {
        //     // alert("3")
        //   addcartBtnText = "View in cart";
     
        // } 
        // else {
        //    addcartBtnText = "View in cart";
        // }
          }
          );
      }else{
         addcartBtnText = "Out of Stock";
      }
     
      return addcartBtnText;
    },
      getuomImage(){
      let imageUrl='';
      let $this=this;
      if( $this.getUomData!='' &&  $this.product!=''){
           $this.getUomData.forEach(function(item){
          if(item.uomName== $this.productDetail.uom){
            imageUrl=item.UomIcon;
          }
      });
      }
    // console.log(imageUrl);

      return imageUrl;

    },
    disclaimer(){
      let disclaimer='';
      let searchKey =(localStorage.getItem('lang')=='ar'?'disclaimerAr':'disclaimer');
      if(this.fetchCompanyPolicyData){
        this.fetchCompanyPolicyData.forEach(function(item){
            if(item.stName==searchKey){
              disclaimer=item.stValue;
            }
        });
      }

      return disclaimer;
    },
     disclaimer23(){

       let disclaimer2 =9;
       console.log(this.getcartList);
       return disclaimer2
     }
   
  },
};
</script>

<style scoped>
.detail_timer{
    position: relative;
    height: 29px!important;
}

.product_qty{ width:auto!important}
.variation_select{
    width: 216px;
    display: block;
    clear: both;
    margin-top: 50px;
    height: 35px;
}
.avilabilty-text span{
   padding:5px;
}

.ps-product--detail .ps-product__sharing a {
  z-index: 999;
}
#product_detail_div{
  min-height: 300px;
}



.wishlist-bg {
  margin-top: 10px;
}

.overview-txt{
    min-height: 150px;
}
.error_page {
    text-align: center;
    padding: 213px 10px;
}
@media only screen and (max-width: 600px) {
     .overview-txt{
        min-height: 10px;
    }

   
}

@media (min-width: 992px) {
  .ps-page__left {
    min-height: 684px; 
  }
}

.zoom-on-hover {
  position: relative;
  overflow: hidden;
}
.zoom-on-hover .normal {
  width: 100%;
}
.zoom-on-hover .zoom {
  position: absolute;
  opacity: 0;
  transform-origin: top left;
}
.zoom-on-hover.zoomed .zoom {
  opacity: 1;
}
.zoom-on-hover.zoomed .normal {
  opacity: 0;
}
.out_stock{
  height: 35px;
  padding: 8px;
}

.ps-product .ps-product__title {
    height: 35px !important;
}
.ps-product--detail .ps-product__sharing a.mail {
    background-color: #747677;
}
.ps-product--detail .ps-product__sharing a.instagram {
    background-color: #dd4b39;
}
.ps-product--detail .ps-product__sharing a.whatsapp {
    background-color: #5fa30e;
}
.ps-product--detail .ps-product__sharing {
   padding-top: 0px;
    padding-bottom: 2rem;
    margin-bottom: 0;
}
.ps-product--detail .ps-product__price del{
   font-size: 1.7rem;
    margin-left: 10px;
}

.ps-product--detail .ps-product__sharing{float: left;
width: 100%;
margin-top: 10px;
}
.productDeatilBtnnav li{ 
  
  /* float:left */
  }
h4 {
    font-size: 14px;
}



</style>
<style>
.vel-modal{
  z-index: 9999999;
}

</style>
